export class ErrorCode {
    constructor() {
        this.errorCodes = [];
        this._login = '';
        this.init();
    }

    get updateError() {
        return this.getUpdateError();
    }

    get loginError() {
        return this.getLoginError();
    }

    get gpError() {
        return this.getGpError();
    }

    get cookieSet() {
        return this.getCookieSet();
    }

    get missingEmail() {
        return this.getMissingEmail();
    }

    get invalidEmail() {
        return this.getInvalidEmail();
    }

    get emailError() {
        return this.getEmailError();
    }

    get unknownError() {
        return this.getUnknownError();
    }

    get technicalError() {
        return this.getTechnicalError();
    }

    get doiErrorMail() {
        return this.getDoiErrorMail()
    }

    set login(value) {
        this._login = value;
        this.updateMessages();
    }


    init() {
        this.errorCodes.push({ code: 0, value: "Bei der Prüfung Ihrer Zugangsdaten ist ein Fehler aufgetreten. Bitte versuchen Sie erneut sich anzumelden."});
        this.errorCodes.push({ code: 403, value: "Die übermittelte Kombination aus Benutzernamen und Passwort konnte nicht gefunden werden. Bitte überprüfen Sie Ihre Eingaben und versuchen Sie es noch einmal."});
        this.errorCodes.push({ code: 10001, value: "Datenübermittlung fehlerhaft, Kundennummer/Nachname fehlen." });
        this.errorCodes.push({ code: 10002, value: "Diese Kundennummer ist in unserem Datenbestand nicht vorhanden." });
        this.errorCodes.push({ code: 10003, value: "Diese Kundennummer gehört zu einem anderen Benutzer. Es ist Ihre Kundennummer? Dann wenden Sie sich bitte an unseren "+ CONFIG.environment.serviceName +"." });
        this.errorCodes.push({ code: 10005, value: "Der Server lieferte einen Fehler, bitte versuchen Sie es zu einem späteren Zeitpunkt erneut oder wenden Sie sich bitte an unseren "+ CONFIG.environment.serviceName +"." });
        this.errorCodes.push({ code: 10006, value: "Die Kundennummer ist bereits vergeben und mit dem Konto {{LOGIN}} verknüpft. Sie kennen dieses Konto nicht? Dann wenden Sie sich bitte an unseren "+ CONFIG.environment.serviceName +"." });
        this.errorCodes.push({ code: 10007, value: "Die Kundennummer ist bereits einem ungültigen Konto zugeordnet. Wenden Sie sich bitte an unseren "+ CONFIG.environment.serviceName +"." });
        this.errorCodes.push({ code: 10008, value: "Die Kundennummer ist bereits vergeben. Wenden Sie sich bitte an unseren "+ CONFIG.environment.serviceName +"." });
        this.errorCodes.push({ code: 10009, value: "Bitte loggen Sie sich unten mit Google ein oder klicken Sie <a class=\"link\" onClick=\"SSO.resetPwShow()\">hier</a>, um Ihr Passwort zurück zu setzten." });
        this.errorCodes.push({ code: 1422, value: "Der Versuch das Passwort wieder herzustellen ist noch im Gange. Bitte warten Sie etwa 10 Minuten, <a class=\"link\" onClick=\"SSO.resetPwShow()\">Sie können danach hier Ihr Passwort neu setzen</a>. Wir bitten um Entschuldigung für die Verzögerung." });
    }

    getByCode(code) {
        code = parseInt(code);
        let message = this.errorCodes[0].value;
        this.errorCodes.forEach(error => {
            if (error.code == code) {
                message = error.value;
            }
        });
        return message;
    }

    updateMessages() {
        this.errorCodes.forEach((error, index) => {
            this.errorCodes[index].value = this.errorCodes[index].value.replace('{{LOGIN}}', this._login);
        });
    }

    getDoiErrorMail() {
        return "Ein Fehler beim Versand der E-Mail ist aufgetreten. Bitte <a onClick='SSO.sendDoi();'>versuchen Sie es noch einmal</a>.";
    }

    getTechnicalError() {
        return "Ein technischer Fehler ist bei der Anfrage aufgetreten. Bitte versuchen Sie es erneut oder kontaktieren Sie unseren "+ CONFIG.environment.serviceName +".";
    }

    getUnknownError() {
        return "Ein unbekannter Fehler ist aufgetreten. Bitte versuchen Sie es erneut oder wenden Sie sich an unseren "+ CONFIG.environment.serviceName +".";
    }

    getEmailError() {
        return "Bei der Prüfung der E-Mail Adresse ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut oder wenden Sie sich an unseren "+ CONFIG.environment.serviceName +".";
    }

    getInvalidEmail() {
        return "Die eingegebene E-Mail Adresse ist nicht gültig. Bitte überprüfen Sie Ihre Angabe.";
    }

    getMissingEmail() {
        return "Bitte geben Sie Ihre E-Mail Adresse ein.";
    }

    getCookieSet() {
        return "Fehler beim Login. Cookie konnte nicht gesetzt werden.";
    }

    getLoginError() {
        return "Fehler beim Login. Die Anfrage lieferte einen Fehler. Bitte versuchen Sie es erneut oder kontaktieren Sie unseren "+ CONFIG.environment.serviceName +".";
    }
    getGpError() {
        return "Fehler bei der Prüfung der übermittelten Kundennummer. Die Anfrage lieferte einen Fehler. Bitte versuchen Sie es erneut oder kontaktieren Sie unseren "+ CONFIG.environment.serviceName +".";
    }
    getUpdateError() {
        return "Bei der Aktualisierung Ihrer Daten ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut oder wenden Sie sich an unseren "+ CONFIG.environment.serviceName +".";
    }


}