import { Base } from './base.js';

export class Logout extends Base {
    constructor() {
        super();
        this.logouButton = document.querySelector('.btn.logout-button');
        this.init();
    }

    init() {
        this.pushHistoryState('logout');

        if (this.logouButton) {
            this.logouButton.addEventListener('click', (event) => {
                event.preventDefault();
                this.logout();
            });
        }
    }

    logout() {
        this.buttonSpinner(this.logouButton);

        let formData = {
            accessToken: CONFIG.accessToken,
        };

        let request = new Request('POST', '/api/logout');
        request.data = formData;
        request.call(response => {
            if (response.status == 'ok') {
                this.utpCookie = false;
                document.location.href = this.baseUrl + "/" +  window.location.search;
            } 
            this.buttonSpinner(this.logouButton, false);
        }, () => {
            this.buttonSpinner(this.logouButton, false);
        });
    }
}