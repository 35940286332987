import { Base } from './base.js';

export class ResetPassword extends Base {
    constructor() {
        super();
        this.emailadressForgotPw = document.querySelector("#emailInput");
        this.resetBtn = document.querySelector("#checkResetPasswordButton");
        this.newpwInput = document.querySelector("#newpwInput");
        this.newpwInputRepeat = document.querySelector("#newpwInputRepeat");
        this.setnewPasswordBtn = document.querySelector("#checkSendnewPasswordButton");
        this.backToLoginBtn = document.querySelector("#backToLoginButton");
        this.btn2click = document.querySelector('.check-email-button');

        this.resetRequest = new Request('POST', '/api/resetpassword');

        this.init();
    }

    init() {

        if(document.querySelectorAll("#newpwInputRepeat").length >= 1) {
            this.btn2click = document.querySelector('.check-sendnewPassword-button');
        }

        if (this.backToLoginBtn) {
            this.backToLoginBtn.addEventListener('click', (event) => {
                event.preventDefault();
                let url = this.baseUrl + "/?ref=" + CONFIG.redirectUrl + "&reason=" + CONFIG.registrationReason;
                window.location.assign(url);
            });
        }

        if (this.setnewPasswordBtn) {
            this.setnewPasswordBtn.addEventListener('click', (event) => {
                this.setPassword(event);
            });
        }

        if (this.resetBtn) {
            this.resetBtn.addEventListener('click', (event) => {
                event.preventDefault();
                this.resendPassword();
            });
        }
    }

    enterSubmit(event) {
        if (event.key == 'Enter') {
            event.preventDefault();
            if (this.btn2click) {
                this.btn2click.click();
            }
        }
    }

    setPassword(event) {
        if (event) {
            event.preventDefault();
        }
        this.buttonSpinner(".check-sendnewPassword-button");
        this.hideErrorMessages();

        let errCount = 0;

        if (this.newpwInput.value == "" || this.newpwInputRepeat.value == "") {
            let formErrorEmail = document.querySelector('.form-error.form-error-email');
            if (formErrorEmail) {
                formErrorEmail.classList.remove('d-none');
                errCount++;
            }
        }
  
        if (errCount != 0) {
            this.buttonSpinner(".check-sendnewPassword-button", false);
        } else {
            let formData = {
                password: this.newpwInput.value,
                code: CONFIG.code,
            };

            this.resetRequest.data = formData;
            this.resetRequest.call(data => {
                this.buttonSpinner(".check-sendnewPassword-button", false);
                if (data.status == 'ok') {
                    this.requestSuccess();
                } else {
                    let errorMessage = "Es ist ein Fehler aufgetreten: <br />"  + response.message;
                    this.requestError(errorMessage);
                }
            }, () => {
                this.buttonSpinner(".check-sendnewPassword-button", false);
                this.requestError(this.errorCode.technicalError);
            });
        }
    };

    resendPassword() {
        this.buttonSpinner(".check-resetPassword-button");
        this.hideErrorMessages();

        if (!this.emailadressForgotPw.value) {
            this.showBlock('.form-error.form-error-email');
            this.buttonSpinner(".check-resetPassword-button", false);
        }
        else {
            let formData = {
                email:this.emailadressForgotPw.value,
            };

            this.resetRequest.data = formData;
            this.resetRequest.call(data => {
                this.buttonSpinner(".check-sendnewPassword-button", false);
                if (data.status == 'ok') {
                    this.requestSuccess();
                    this.hideBlock("#emailFields");
                    this.hideBlock(this.resetBtn);
                    document.querySelector(".main-description-text").innerHTML = "Wir haben Ihnen eine Nachricht an Ihre E-Mail-Adresse gesendet mit einem Link, unter dem Sie Ihr neues Passwort setzen können. Bitte schauen Sie in Ihrem Postfach nach.<br /><a href=\"" + window.location.protocol + "//" +  window.location.host  +  window.location.search + "\">Zurück zum Login</a>";
                } else {
                    let errorMessage = "Es ist ein Fehler aufgetreten: <br />"  + response.message;
                    this.requestError(errorMessage);
                }
            }, () => {
                this.buttonSpinner(".check-sendnewPassword-button", false);
                this.requestError(this.errorCode.technicalError);
            });
        }
    };

    requestSuccess() {
        document.querySelector(".main-description-text").innerHTML = "Vielen Dank! Das Passwort wurde erfolgreich geändert.";
        this.btn2click=document.querySelector(".back-toLogin-button");
        this.hideBlock("#newPwFields")
        this.hideBlock("#btnSendnewPasswordBlock")
        this.showBlock("#btnBackToLoginBlock")
    }
}