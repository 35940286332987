export default class Request {
    constructor(method = 'GET', url = false, requestData = [], loadingButton = '.submitButton') {
        this._method = method;
        this._url = url;
        this._request = false;
        this._requestData = requestData;
        this._abortController = new AbortController();
        this._signal = this._abortController.signal;
        this._loadingButton = loadingButton;
        this._isJson = true;
    }
    
    set method(value) {
        switch(value) {
            case 'GET' : this._method = 'GET'; break;
            case 'POST' : this._method = 'POST'; break;
            case 'PUT' : this._method = 'PUT'; break;
            case 'DELETE' : this._method = 'DELETE'; break;
            default: this._method = 'GET'; break;
        }
    }

    set isJson(value) {
        this._isJson = Boolean(value);
    }
    
    set url(value) {
        this._url = value;
    }
    
    set data(value) {
        this._requestData = value;
    }
    
    set formData(value) {
        this._requestData = new FormData(value);
        for (const element of this._requestData.entries()) {
            let input = document.querySelector('[name="'+element[0]+'"]');
            if (input) {
                if (input.dataset.ignore && input.value == '') {
                    this._requestData.set(element[0], 'ignore');
                }
            }
        }
    }
    
    call(successCallback, errorCallback) {
        if(this._request) {
            this._abortController.abort('New Request');
        }

        this._abortController = new AbortController();
        this._signal = this._abortController.signal;
        this._request = true;
        
        let details = {
            method: this._method,
            signal: this._signal,
            credentials: 'include',
        };
        
        if (this._method == 'POST') {
            details['body'] = new URLSearchParams(this._requestData);
        }

        fetch(this._url, details)
        .then(res => (this._isJson) ? res.json() : res.text())
        .then(data => {
            this._request = false;
            if(typeof successCallback == 'function') {
                successCallback(data);
           }
        })
        .catch(data => {
            this._request = false;
            if(typeof errorCallback == 'function') {
                errorCallback(data);
            }
        });
    }
}
