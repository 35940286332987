import { Base } from './base.js';

export class PersonalData extends Base {
    constructor() {
        super();
        this.btn2click = document.querySelector('#dataAddButton');
        this.fieldSalutation = document.querySelector('#comment-userinfo-salutation');
        this.fieldFirstName = document.querySelector('#comment-userinfo-firstName');
        this.fieldLastName = document.querySelector('#comment-userinfo-lastName');
        this.fieldEmail = document.querySelector('#email');
        this.init();
    }

    init() {
        this.pushHistoryState('addPersonalData');

        document.querySelector("body").addEventListener("keyup", () => {
            this.hideErrorMessages();
        });

        if(this.btn2click) {
            this.btn2click.addEventListener('click', (event) => {
                event.preventDefault();
                this.submit();
            });
        }

        if (this.fieldSalutation) {
            this.fieldSalutation.addEventListener('change', () => {
                this.hideErrorMessages();
            });
        }
    }

    enterSubmit(event) {
        if (event.key == 'Enter') {
            event.preventDefault();
            if (this.btn2click) {
                this.btn2click.click();
            }
        }
    }

    errorSubmit(message) {
        let element = document.querySelector('#dataAddErr');
        if (element) {
            element.innerHTML = message;
            this.showBlock(element);
        }
    }

    submit() {
        if (this.fieldSalutation.value && this.fieldFirstName.value && this.fieldLastName.value) {
            this.buttonSpinner('#dataAddButton');
            this.hideErrorMessages();
            
            let formData = {
                email: this.fieldEmail.value,
                salutation: this.fieldSalutation.value,
                firstName: this.fieldFirstName.value,
                lastName: this.fieldLastName.value,
            };

            let request = new Request('POST', '/api/addpersonaldata');

            request.data = formData;
            request.call(response => {
                if (response.status == 'ok') {
                    if (response.data.accessToken) {
                        this.utpCookie = response.data.accessToken;
                        document.location.href = CONFIG.redirectUrl;
                    } else {
                        this.errorSubmit('Die Aktualisierung Ihrer Daten war erfolgreich, aber Ihre Session konnte nicht aktualisiert werden. Bitte loggen Sie sich aus und melden sich sich anschließend erneut an. Entschuldigen Sie bitte diesen zusätzlichen Schritt.')
                    }
                } else {
                    this.errorSubmit(this.errorCode.updateError);
                    this.buttonSpinner('#dataAddButton', false);
                }
            }, () => {
                this.errorSubmit(this.errorCode.updateError);
                this.buttonSpinner("#dataAddButton", false);
            });
        } else {
            this.errorSubmit('Bitte füllen Sie alle Felder aus.');
        }
    }
}